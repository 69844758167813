import React from "react"
import Address from "../components/address"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col } from "react-bootstrap";

const PricingPage = () => (
  <Layout>
    <SEO title="Pricing" />
      <Container>
        <h1 class="display-4 text-center">Pricing</h1>
        <Row>
          <Col md="1">
          </Col>
          <Col md="9">
            <p class="text-secondary">We offer various different Pricing schemes for our customers. Our customers can pay us by hours, projects or designated dates.</p>
          </Col> 
        </Row>
        <Row>
          <Col>Hourly
          </Col>
          <Col>By Project</Col>
          <Col>Designated Dates</Col>

        </Row>
       
      </Container>
      <Address/>
  </Layout>
)

export default PricingPage